import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, Button, VStack, Spinner } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useAuth } from '../AuthContext';
import { app } from '../../Firebase';

const fetchUserData = async (uid, setUserData, navigate) => {
  const db = getFirestore(app);
  const userDocRef = doc(db, 'users', uid);
  const userDocSnapshot = await getDoc(userDocRef);

  if (userDocSnapshot.exists()) {
    setUserData(userDocSnapshot.data());
  } else {
    console.log('No user data found in the database.');
    navigate('/login'); // Redirect to login if no user data is found
  }
};

const formatEpochTime = (epoch) => {
  const date = new Date(epoch * 1000);
  return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
};

const SettingsUtil = () => {
  const { currentUser, logout } = useAuth();
  const [userData, setUserData] = useState(null);
  const [loadingPortal, setLoadingPortal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      fetchUserData(currentUser.uid, setUserData, navigate);
    } else {
      navigate('/login');
    }
  }, [currentUser, navigate]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleOpenStripePortal = async () => {
    setLoadingPortal(true);
    try {
      if (userData?.customerId) {
        const functions = getFunctions(app);
        const createStripePortalSession = httpsCallable(functions, 'createStripePortalSession');
        const { data } = await createStripePortalSession({ customerId: userData.customerId, returnUrl: window.location.origin });
        window.location.href = data.url;
      } else {
        console.error('User does not have a customerId.');
        setLoadingPortal(false);
      }
    } catch (error) {
      console.error('Error opening Stripe portal:', error);
      setLoadingPortal(false);
    }
  };

  if (!currentUser || !userData) {
    return (
      <Box
        minHeight="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          maxWidth="400px"
          width="100%"
          p={8}
          borderRadius={8}
          boxShadow="lg"
          bg="rgba(0, 0, 0, 0.5)"
          backdropFilter="blur(10px)"
        >
          <VStack spacing={6} align="center">
            <Heading as="h1" size="xl" textAlign="center" color="white">
              Settings
            </Heading>
            <Spinner />
          </VStack>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        maxWidth="400px"
        width="100%"
        p={8}
        borderRadius={8}
        boxShadow="lg"
        bg="rgba(0, 0, 0, 0.5)"
        backdropFilter="blur(10px)"
      >
        <VStack spacing={6} align="center">
          <Heading as="h1" size="xl" textAlign="center" color="white" mb={6}>
            Settings
          </Heading>
          {userData && (
            <Box border="1px" borderColor="gray.200" p={4} mb={4} borderRadius={8} bg="rgba(255, 255, 255, 0.1)">
              <Heading as="h2" size="md" mb={2} color="white">
                User Information
              </Heading>
              <Text color="white" mb={2}>
                <strong>Name:</strong> {userData.name}
              </Text>
              <Text color="white" mb={2}>
                <strong>Email:</strong> {userData.email}
              </Text>
              <Text color="white" mb={2}>
                <strong>Plan:</strong> {userData.plan}
              </Text>
              {userData.plan !== 'Free' && (
                <>
                  <Text color="white" mb={2}>
                    <strong>Billing Period Start:</strong> {formatEpochTime(userData.currentPeriodStart)}
                  </Text>
                  <Text color="white" mb={2}>
                    <strong>Billing Period End:</strong> {formatEpochTime(userData.currentPeriodEnd)}
                  </Text>
                </>
              )}
            </Box>
          )}
          {userData.plan !== 'Free' && (
            <Button
              colorScheme="blue"
              onClick={handleOpenStripePortal}
              size="lg"
              width="full"
              _hover={{
                bgGradient: 'linear(to-r, blue.600, blue.400)',
              }}
              isDisabled={!userData?.customerId || loadingPortal}
            >
              {loadingPortal ? 'Please wait...' : 'Open Stripe Portal'}
            </Button>
          )}
          <Button
            colorScheme="red"
            onClick={handleLogout}
            size="lg"
            width="full"
            _hover={{
              bgGradient: 'linear(to-r, red.600, red.400)',
            }}
          >
            Logout
          </Button>
        </VStack>
      </Box>
    </Box>
  );
};

export default SettingsUtil;
