import React from "react";
import { Box } from "@chakra-ui/react";
import GridItem from "./vault/GridItem";

const Vault = () => {
  return (
    <Box>
      <GridItem />
    </Box>
  );
};

export default Vault;
