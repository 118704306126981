import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  FormControl,
  FormLabel,
  Textarea,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useAuth } from './AuthContext';
import { db } from '../Firebase';
import { collection, addDoc, doc, getDoc } from 'firebase/firestore';

const Request = () => {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState({
    automationType: '',
    reason: '',
    idealSolution: '',
  });
  const [isEligiblePlan, setIsEligiblePlan] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userPlan = userDoc.data().plan;
          setIsEligiblePlan(userPlan !== 'Free');
        }
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isEligiblePlan) {
      return;
    }

    try {
      await addDoc(collection(db, 'requests'), {
        ...formData,
        userEmail: currentUser.email,
        userName: currentUser.displayName,
      });
      toast({
        title: 'Request submitted',
        description: 'We will review your request soon!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      // Clear form after submission
      setFormData({
        automationType: '',
        reason: '',
        idealSolution: '',
      });
    } catch (error) {
      toast({
        title: 'Submission failed',
        description: 'There was an error submitting your request. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Error adding document: ', error);
    }
  };

  return (
    <Box py={20} maxW="900px" mx="auto" px={4}>
      <Flex direction="column" alignItems="center" mb={12}>
        <Heading as="h1" size="2xl" color="white" mb={12}>
          Request An Automation
        </Heading>
        <Stack align="center" spacing={4} mb={8} maxWidth="500px">
          <Text color="white" fontSize="lg" textAlign="center">
            We take requests from the community for automations to add to the resource vault.
          </Text>
          <Text color="white" fontSize="lg" textAlign="center">
            Let us know what you want to see!
          </Text>
        </Stack>
        <Box
          as="form"
          onSubmit={handleSubmit}
          maxWidth="500px"
          width="100%"
          p={8}
          borderRadius={8}
          boxShadow="lg"
          bg="rgba(0, 0, 0, 0.5)"
          backdropFilter="blur(10px)"
          color="white"
        >
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>What kind of automation are you looking for?</FormLabel>
              <Textarea
                name="automationType"
                value={formData.automationType}
                onChange={handleChange}
                placeholder="Describe the automation you're looking for"
                bg="rgba(255, 255, 255, 0.1)"
                color="white"
                isDisabled={!isEligiblePlan}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Why do you need it?</FormLabel>
              <Textarea
                name="reason"
                value={formData.reason}
                onChange={handleChange}
                placeholder="Explain why you need this automation"
                bg="rgba(255, 255, 255, 0.1)"
                color="white"
                isDisabled={!isEligiblePlan}
              />
            </FormControl>
            <FormControl>
              <FormLabel>What does the ideal solution look like?</FormLabel>
              <Textarea
                name="idealSolution"
                value={formData.idealSolution}
                onChange={handleChange}
                placeholder="Describe your ideal solution"
                bg="rgba(255, 255, 255, 0.1)"
                color="white"
                isDisabled={!isEligiblePlan}
              />
            </FormControl>
            <Button
              type="submit"
              bgGradient="linear(to-r, blue.500, blue.300)"
              color="white"
              size="lg"
              width="full"
              mb={4}
              _hover={{
                bgGradient: 'linear(to-r, blue.600, blue.400)',
              }}
              isDisabled={!isEligiblePlan}
              cursor={isEligiblePlan ? 'pointer' : 'not-allowed'}
              opacity={isEligiblePlan ? 1 : 0.5}
            >
              Submit
            </Button>
            {!isEligiblePlan && (
              <Text color="red.500" textAlign="center" mt={2}>
                You must be on a Premium or Expert plan to submit a request.
              </Text>
            )}
          </Stack>
        </Box>
      </Flex>
    </Box>
  );
};

export default Request;
