import React from "react";
import { Box } from "@chakra-ui/react";
import PricingPart from "./dashboard/PricingPart";

const Pricing = () => {
  return (
    <Box>
      <PricingPart/>
    </Box>
  );
};

export default Pricing;
