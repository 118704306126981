import React, { useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { scroller } from "react-scroll";
import {
  Box,
  Text,
  Flex,
  VStack,
  Heading,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  HStack,
  Tag,
  CloseButton,
  Button,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { auth, db } from "../../Firebase"; // Ensure auth is imported correctly
import resourcesData from "./Resources"; // Import resources
import parse from "html-react-parser"; // Import html-react-parser

// Dynamically import images
const importAll = (requireContext) =>
  requireContext.keys().reduce((acc, next) => {
    acc[next.replace("./", "./images/")] = requireContext(next);
    return acc;
  }, {});

const images = importAll(
  require.context("./images", false, /\.(jpg|jpeg|png|svg)$/)
);

// Enhance resources data with the correct image references
const resources = resourcesData.map((resource) => ({
  ...resource,
  image: images[resource.image],
}));

const allTags = [...new Set(resources.flatMap((resource) => resource.tags))];

const VaultItem = () => {
  const { resourceId } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  const userDocRef = user ? doc(db, "users", user.uid) : null;
  const [userDoc, loading] = useDocument(userDocRef);

  const isPremiumUser = userDoc?.data()?.plan === "Premium";

  const filteredResources = resources.filter(
    (resource) =>
      (resource.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        resource.tags.some((tag) =>
          tag.toLowerCase().includes(searchTerm.toLowerCase())
        )) &&
      (selectedTags.length === 0 ||
        selectedTags.every((tag) => resource.tags.includes(tag)))
  );

  const handleTagChange = (tag) => {
    setSelectedTags(
      selectedTags.includes(tag)
        ? selectedTags.filter((selectedTag) => selectedTag !== tag)
        : [...selectedTags, tag]
    );
  };

  const handleAccessContent = (resource) => {
    if (resource.premium && !isPremiumUser) {
      navigate("/pricing");
    } else {
      window.location.href = resource.externalLink;
    }
  };

  const selectedResource = resources.find(
    (resource) => resource.link.split("/")[1] === resourceId
  );

  const handleScrollToTop = () => {
    scroller.scrollTo("top", {
      duration: 800,
      delay: 0,
      offset: "-100px",
      smooth: "easeInOutQuart",
    });
  };

  if (loading) {
    return (
      <Center height="100vh">
        <Spinner size="xl" color="white" />
      </Center>
    );
  }

  return (
    <>
      <Center width="100%" py={{ base: "8px", md: "40px" }} name="top">
        <Flex
          direction={{ base: "column", md: "row" }}
          maxWidth="1000px"
          width="100%"
        >
          <Box
            p={4}
            width={{ base: "100%", md: "70%" }}
            order={{ base: 1, md: 2 }}
          >
            {selectedResource ? (
              <>
                <Heading fontSize={{ base: "4xl", md: "4xl" }} mb={4} color="white">
                  {selectedResource.title}
                </Heading>
                <Box mb={4}>
                  <img
                    src={selectedResource.image}
                    alt={selectedResource.title}
                    width="100%"
                    style={{ borderRadius: "25px" }}
                  />
                </Box>
                <Flex direction="column" alignItems="flex-start">
                  <Box width="100%" mb={4}>
                    <Button
                      h={16}
                      colorScheme={
                        selectedResource.premium
                          ? "purple"
                          : "teal"
                      }
                      bgGradient={
                        selectedResource.premium
                          ? "linear(to-r, purple.500, purple.300)"
                          : "linear(to-r, blue.500, blue.300)"
                      }
                      _hover={{
                        bgGradient:
                          selectedResource.premium
                            ? "linear(to-r, purple.600, purple.400)"
                            : "linear(to-r, blue.600, blue.400)",
                      }}
                      onClick={() => handleAccessContent(selectedResource)}
                      width="100%" // Ensure the button takes the full width of its container
                      whiteSpace="normal" // Allow text to wrap
                      textAlign="center" // Center align the text
                    >
                      <Text fontSize="20px">
                        {selectedResource.premium
                          ? isPremiumUser
                            ? "Access Premium Content"
                            : "Upgrade your plan to get this resource"
                          : "Access Content"}
                      </Text>
                    </Button>
                  </Box>
                  <Text fontSize="xl" mb={4} color="white" width="100%">
                    {parse(selectedResource.description)}
                  </Text>
                </Flex>
              </>
            ) : (
              <Text fontSize="md" color="red.500">
                Resource not found.
              </Text>
            )}
          </Box>
          <Box
            p={4}
            width={{ base: "100%", md: "30%" }}
            color="white"
            order={{ base: 2, md: 1 }}
          >
            <Heading fontSize="xl" mb={4}>
              Search Resources
            </Heading>
            <HStack mb={8} width={{ base: "100%", md: "100%" }} spacing={0}>
              <Input
                placeholder="Search resources and tags..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                borderRightRadius={0}
                color="white"
              />
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon ml={6} />}
                  borderLeftRadius={0}
                  width="auto"
                >
                  Tags
                </MenuButton>
                <MenuList bg="white" color="black">
                  {allTags
                    .filter((tag) =>
                      tag.toLowerCase().includes(searchTerm.toLowerCase())
                    )
                    .map((tag) => (
                      <MenuItem
                        key={tag}
                        onClick={() => handleTagChange(tag)}
                        _hover={{ bg: "gray.200" }}
                      >
                        {tag}
                      </MenuItem>
                    ))}
                </MenuList>
              </Menu>
            </HStack>
            <VStack align="start" spacing={3}>
              {selectedTags.map((tag) => (
                <Tag key={tag} size="sm" variant="solid" colorScheme="blue">
                  {tag.toUpperCase()}
                  <CloseButton
                    size="sm"
                    ml={2}
                    onClick={() => handleTagChange(tag)}
                  />
                </Tag>
              ))}
            </VStack>
            <VStack align="start" spacing={2} mt={4}>
              {filteredResources.length > 0 ? (
                filteredResources.map((resource, index) => (
                  <Box key={index}>
                    <Link
                      to={`/vault-item/${resource.link.split("/")[1]}`}
                      onClick={handleScrollToTop}
                    >
                      <Text
                        fontSize="md"
                        color="blue.300"
                        _hover={{ textDecoration: "underline" }}
                      >
                        {resource.title}
                      </Text>
                    </Link>
                  </Box>
                ))
              ) : (
                <Text fontSize="md" color="red.500">
                  Nothing matches your request.
                </Text>
              )}
            </VStack>
          </Box>
        </Flex>
      </Center>
    </>
  );
};

export default VaultItem;
