import React, { useState } from 'react';
import { Box, Button, Container, Flex, Grid, Heading, Image, Input, Text, Wrap, WrapItem, Tag, Menu, MenuButton, MenuItem, MenuList, HStack, CloseButton } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import resources from './Resources';

// Dynamically import images
const importAll = (requireContext) => requireContext.keys().reduce((acc, next) => {
  acc[next.replace('./', './images/')] = requireContext(next);
  return acc;
}, {});

const images = importAll(require.context('./images', false, /\.(jpg|jpeg|png|svg)$/));

// Enhance resources data with the correct image references
const courses = resources.map(course => ({
  ...course,
  image: images[course.image]
}));

const allTags = [...new Set(courses.flatMap(course => course.tags))];

const GridItem = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const navigate = useNavigate();

  const filteredCourses = courses.filter(course =>
    (course.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    course.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    course.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()))) &&
    (selectedTags.length === 0 || selectedTags.every(tag => course.tags.includes(tag)))
  );

  const handleTagChange = (tag) => {
    setSelectedTags(
      selectedTags.includes(tag)
        ? selectedTags.filter(selectedTag => selectedTag !== tag)
        : [...selectedTags, tag]
    );
  };

  const handleCourseClick = (link) => {
    navigate(`/vault-item${link}`);
  };

  return (
    <Box py={20}>
      <Container maxW="1200px">
        <Flex direction="column" alignItems="center" mb={12}>
          <Heading as="h1" size="2xl" color="white" mb={4}>
            The Vault
          </Heading>
          <Text color="white" fontSize="xl" mb={8} textAlign="center">
            Resources, scripts, automations, workflows <br/> and guides to help supercharge your business.
          </Text>
          <HStack mb={8} width={{ base: '100%', md: '50%' }} spacing={0}>
            <Input 
              placeholder="Search courses and tags..." 
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              borderRightRadius={0}
              color="white"
            />
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon ml={2}/>} borderLeftRadius={0} width="auto">
                Tags
              </MenuButton>
              <MenuList>
                {allTags
                  .filter(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()))
                  .map(tag => (
                    <MenuItem key={tag} onClick={() => handleTagChange(tag)}>
                      {tag}
                    </MenuItem>
                  ))}
              </MenuList>
            </Menu>
          </HStack>
          <Wrap mb={8}>
            {selectedTags.map(tag => (
              <WrapItem key={tag}>
                <Tag size="sm" variant="solid" colorScheme="blue">
                  {tag.toUpperCase()}
                  <CloseButton size="sm" ml={2} onClick={() => handleTagChange(tag)} />
                </Tag>
              </WrapItem>
            ))}
          </Wrap>
        </Flex>

        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={8}>
          {filteredCourses.map((course, index) => (
            <Box 
              key={index}
              onClick={() => handleCourseClick(course.link)}
              border="1px" 
              borderColor="gray.200" 
              p={8} 
              borderRadius={8} 
              bg="rgba(255, 255, 255, 0.1)"
              cursor="pointer" 
              transition="all 0.3s ease"
              _hover={{
                transform: 'scale(1.02)',
                boxShadow: '0 0 20px rgba(255, 255, 255, 0.5)',
              }}
            >
              <Box position="relative">
                {course.premium && (
                  <Box
                    position="absolute"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                    bgGradient="linear(to-r, purple.500, purple.300)"
                    borderRadius="10px"
                    zIndex="-1"
                  />
                )}
                <Image src={course.image} alt={course.title} mb={4} borderRadius={10} />
              </Box>
              <Heading as="h3" size="lg" color="white" mb={4}>
                {course.title}
              </Heading>
              <Wrap mb={6}>
                {course.tags.map((tag, idx) => (
                  <WrapItem key={idx}>
                    <Tag size="sm" variant="solid" colorScheme="blue">
                      {tag.toUpperCase()}
                    </Tag>
                  </WrapItem>
                ))}
                {course.premium && (
                  <WrapItem>
                    <Tag size="sm" variant="solid" colorScheme="purple">
                      PREMIUM
                    </Tag>
                  </WrapItem>
                )}
              </Wrap>
              <Button 
                _hover={{
                  bgGradient: "linear(to-r, blue.600, blue.400)",
                }}
                mr={4}
                bgGradient="linear(to-r, blue.500, blue.300)"
                color="white"
              >
                View Resource
              </Button>
            </Box>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default GridItem;
