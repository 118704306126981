import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyDHU4mtOnUKXbsmbZKpLWJ779uDejPMEMU",
  authDomain: "ai-automation-mastery.firebaseapp.com",
  projectId: "ai-automation-mastery",
  storageBucket: "ai-automation-mastery.appspot.com",
  messagingSenderId: "313936669121",
  appId: "1:313936669121:web:ce8e59ff17eeec033e6740"
};

const app = initializeApp(firebaseConfig); // Initialize the app first
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app); // Use the initialized app

export { app, db, auth, functions }; // Export auth
