import React from 'react';
import { Box, Flex, Text, Image, IconButton, Stack } from '@chakra-ui/react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaDiscord } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import icon from '../../assets/icon.png';

const Footer = () => {
  return (
    <Box
      bg="rgba(0, 0, 0, 0.5)"
      backdropFilter="blur(10px)"
      py={8}
      borderTopWidth={1}
      borderTopColor="gray.700"
    >
      <Flex
        maxWidth="container.lg"
        mx="auto"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        direction={{ base: 'column', md: 'row' }}
      >
        <Box mb={{ base: 4, md: 0 }} textAlign={{ base: 'center', md: 'left' }}>
          <Link to="/">
            <Image src={icon} alt="Logo" w="50px" mx={{ base: 'auto', md: '0' }} />
          </Link>
        </Box>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={6}
          fontWeight="bold"
          textAlign={{ base: 'center', md: 'left' }}
        >
          <Link to="/about">
            <Text color="white" _hover={{ color: 'blue.300' }}>
              About
            </Text>
          </Link>
          <Link to="/contact">
            <Text color="white" _hover={{ color: 'blue.300' }}>
              Contact
            </Text>
          </Link>
          <Link to="/terms">
            <Text color="white" _hover={{ color: 'blue.300' }}>
              Terms of Service
            </Text>
          </Link>
          <Link to="/privacy">
            <Text color="white" _hover={{ color: 'blue.300' }}>
              Privacy Policy
            </Text>
          </Link>
        </Stack>
        <Stack
          direction="row"
          spacing={4}
          mt={{ base: 4, md: 0 }}
          justify={{ base: 'center', md: 'flex-start' }}
        >
          <IconButton
            as="a"
            href="#"
            icon={<FaFacebook />}
            aria-label="Facebook"
            colorScheme="facebook"
            variant="ghost"
            color="white"
            _hover={{ color: 'blue.300' }}
          />
          <IconButton
            as="a"
            href="#"
            icon={<FaTwitter />}
            aria-label="Twitter"
            colorScheme="twitter"
            variant="ghost"
            color="white"
            _hover={{ color: 'blue.300' }}
          />
          <IconButton
            as="a"
            href="#"
            icon={<FaInstagram />}
            aria-label="Instagram"
            colorScheme="instagram"
            variant="ghost"
            color="white"
            _hover={{ color: 'blue.300' }}
          />
          <IconButton
            as="a"
            href="#"
            icon={<FaLinkedin />}
            aria-label="LinkedIn"
            colorScheme="linkedin"
            variant="ghost"
            color="white"
            _hover={{ color: 'blue.300' }}
          />
          <IconButton
            as="a"
            href="https://discord.gg/bd9B5T3Jkh"
            icon={<FaDiscord />}
            aria-label="Discord"
            colorScheme="purple"
            variant="ghost"
            color="white"
            _hover={{ color: 'blue.300' }}
          />
        </Stack>
      </Flex>
      <Text color="white" mt={8} fontSize="sm" textAlign="center">
        &copy; {new Date().getFullYear()} My App. All rights reserved.
      </Text>
    </Box>
  );
};

export default Footer;
