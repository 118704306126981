import React, { useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  Button,
  useToast,
} from '@chakra-ui/react';
import { db } from '../Firebase';
import { collection, addDoc } from 'firebase/firestore';

const Build = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    appIdea: '',
    experience: '',
    tenWeekProgram: false,
    socialMedia: false,
    capital: '',
    partnership: '',
    appExistence: '',
  });
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'build'), formData);
      toast({
        title: 'Thank you!',
        description: 'We will be in touch soon!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      // Clear form after submission
      setFormData({
        name: '',
        email: '',
        phone: '',
        appIdea: '',
        experience: '',
        tenWeekProgram: false,
        socialMedia: false,
        capital: '',
        partnership: '',
        appExistence: '',
      });
    } catch (error) {
      toast({
        title: 'Submission failed',
        description: 'There was an error submitting your form. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Error adding document: ', error);
    }
  };

  return (
    <>
      <Box py={20} maxW="900px" mx="auto" px={4}>
        <Flex direction="column" alignItems="center" mb={12}>
          <Heading as="h1" size="2xl" color="white" mb={4}>
            Build With Us
          </Heading>
          <Stack align="start" spacing={4} mb={8} maxWidth="500px">
            <Text color="white" fontSize="lg">
              Do you have an idea for an app?
            </Text>
            <Text color="white" fontSize="lg">
              Do you want to build it but don't know where to start?
            </Text>
            <Text color="white" fontSize="lg">
              Our AIA Mastery program will partner with you to build your idea!
            </Text>
            <Text color="white" fontSize="lg">
              We provide the support and development to build your app within 10 weeks.
            </Text>
            <Text color="white" fontSize="lg">
              Fill out the form below to get started.
            </Text>
          </Stack>
          <Box as="form" onSubmit={handleSubmit} maxWidth="500px" width="100%" p={8} borderRadius={8} boxShadow="lg" bg="rgba(0, 0, 0, 0.5)" backdropFilter="blur(10px)" color="white">
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter name"
                  bg="rgba(255, 255, 255, 0.1)"
                  color="white"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter email"
                  bg="rgba(255, 255, 255, 0.1)"
                  color="white"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Phone</FormLabel>
                <Input
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Enter phone"
                  bg="rgba(255, 255, 255, 0.1)"
                  color="white"
                />
              </FormControl>
              <FormControl>
                <FormLabel>App Idea</FormLabel>
                <Textarea
                  name="appIdea"
                  value={formData.appIdea}
                  onChange={handleChange}
                  placeholder="Enter your app idea"
                  bg="rgba(255, 255, 255, 0.1)"
                  color="white"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Experience</FormLabel>
                <Textarea
                  name="experience"
                  value={formData.experience}
                  onChange={handleChange}
                  placeholder="Write about your experience"
                  bg="rgba(255, 255, 255, 0.1)"
                  color="white"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Why do you think this app needs to exist?</FormLabel>
                <Textarea
                  name="appExistence"
                  value={formData.appExistence}
                  onChange={handleChange}
                  placeholder="Explain why this app needs to exist"
                  bg="rgba(255, 255, 255, 0.1)"
                  color="white"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Are you willing to commit to a 10-week program?</FormLabel>
                <Select
                  name="tenWeekProgram"
                  value={formData.tenWeekProgram ? 'yes' : 'no'}
                  onChange={(e) => handleChange({ target: { name: 'tenWeekProgram', value: e.target.value === 'yes' } })}
                  bg="rgba(255, 255, 255, 0.1)"
                  color="white"
                >
                  <option value="yes" style={{ color: 'black' }}>Yes</option>
                  <option value="no" style={{ color: 'black' }}>No</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Are you willing to post on social media?</FormLabel>
                <Select
                  name="socialMedia"
                  value={formData.socialMedia ? 'yes' : 'no'}
                  onChange={(e) => handleChange({ target: { name: 'socialMedia', value: e.target.value === 'yes' } })}
                  bg="rgba(255, 255, 255, 0.1)"
                  color="white"
                >
                  <option value="yes" style={{ color: 'black' }}>Yes</option>
                  <option value="no" style={{ color: 'black' }}>No</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Do you have capital to invest in your app?</FormLabel>
                <Select
                  name="capital"
                  value={formData.capital}
                  onChange={handleChange}
                  bg="rgba(255, 255, 255, 0.1)"
                  color="white"
                >
                  <option value="" disabled style={{ color: 'black' }}>Select an option</option>
                  <option value="none" style={{ color: 'black' }}>No</option>
                  <option value="less_than_5000" style={{ color: 'black' }}>Less than $5000</option>
                  <option value="5000_to_10000" style={{ color: 'black' }}>$5000 to $10000</option>
                  <option value="more_than_10000" style={{ color: 'black' }}>More than $10000</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>What is the level of partnership you are looking for?</FormLabel>
                <Select
                  name="partnership"
                  value={formData.partnership}
                  onChange={handleChange}
                  bg="rgba(255, 255, 255, 0.1)"
                  color="white"
                >
                  <option value="" disabled style={{ color: 'black' }}>Select an option</option>
                  <option value="mentor" style={{ color: 'black' }}>Mentor</option>
                  <option value="partner" style={{ color: 'black' }}>Partner</option>
                  <option value="investor" style={{ color: 'black' }}>Investor</option>
                  <option value="full_support" style={{ color: 'black' }}>Full Support</option>
                </Select>
              </FormControl>
              <Button
                type="submit"
                bgGradient="linear(to-r, blue.500, blue.300)"
                color="white"
                size="lg"
                width="full"
                mb={4}
                _hover={{
                  bgGradient: "linear(to-r, blue.600, blue.400)",
                }}
              >
                Submit
              </Button>
            </Stack>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default Build;
