import React from "react";
import {
  Box,
  Flex,
  Spacer,
  Button,
  Image,
  Text,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useAuth } from "../AuthContext";
import icon from "../../assets/icon.png";

const Navbar = () => {
  const { currentUser } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      position="sticky"
      top={0}
      zIndex={10}
      bg="rgba(0, 0, 0, 0.5)"
      backdropFilter="blur(10px)"
      py={4}
      borderBottomWidth={1}
      borderBottomColor="gray.700"
    >
      <Flex alignItems="center" maxWidth="container.lg" mx="auto" px={6}>
        <Link to="/">
          <Image src={icon} alt="Logo" w="50px" mr={4} />
        </Link>
        <Spacer display={{ base: "none", md: "flex" }} />
        <Flex display={{ base: "none", md: "flex" }} alignItems="center">
          <Link to="/build">
            <Text color="white" fontWeight="bold" fontSize="lg" mr={8}>
              Build
            </Text>
          </Link>
          <Link to="/request">
            <Text color="white" fontWeight="bold" fontSize="lg" mr={8}>
              Request
            </Text>
          </Link>
          <Link to="/vault">
            <Text color="white" fontWeight="bold" fontSize="lg" mr={8}>
              The Vault
            </Text>
          </Link>
          <Link to="/pricing">
            <Text color="white" fontWeight="bold" fontSize="lg" mr={8}>
              Pricing
            </Text>
          </Link>
        </Flex>
        {currentUser ? (
          <Flex display={{ base: "none", md: "flex" }} alignItems="center">
            <Link to="/settings">
              <Button colorScheme="gray">Settings</Button>
            </Link>
          </Flex>
        ) : (
          <Flex display={{ base: "none", md: "flex" }} alignItems="center">
            <Link to="/signup">
              <Button
                _hover={{
                  bgGradient: "linear(to-r, blue.600, blue.400)",
                }}
                mr={4}
                bgGradient="linear(to-r, blue.500, blue.300)"
                color="white"
              >
                Sign Up
              </Button>
            </Link>
            <Link to="/login">
              <Button colorScheme="gray">Log In</Button>
            </Link>
          </Flex>
        )}
        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          icon={<HamburgerIcon boxSize={6} />}
          variant="ghost"
          aria-label="Toggle Navigation"
          color="white"
          _hover={{ bg: "transparent" }}
          ml="auto" // This ensures the IconButton is on the right side
          size="lg" // Makes the IconButton larger
          p={4} // Adds padding to make it larger
        />
      </Flex>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent bg="rgba(0, 0, 0, 0.5)" backdropFilter="blur(10px)">
          <DrawerCloseButton color="white" />
          <DrawerHeader borderBottomWidth={1} borderBottomColor="gray.700" color="white">
            Menu
          </DrawerHeader>
          <DrawerBody >
            <Stack mt={6} as="nav" spacing={6}>
              <Link to="/build" onClick={onClose}>
                <Text fontWeight="bold" fontSize="lg" color="white">
                  Build
                </Text>
              </Link>
              <Link to="/request" onClick={onClose}>
                <Text fontWeight="bold" fontSize="lg" color="white">
                  Request
                </Text>
              </Link>
              <Link to="/vault" onClick={onClose}>
                <Text fontWeight="bold" fontSize="lg" color="white">
                  The Vault
                </Text>
              </Link>
              <Link to="/pricing" onClick={onClose}>
                <Text fontWeight="bold" fontSize="lg" color="white">
                  Pricing
                </Text>
              </Link>
              {currentUser ? (
                <>
                  <Link to="/settings" onClick={onClose}>
                    <Button colorScheme="gray">Settings</Button>
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/signup" onClick={onClose}>
                    <Button
                      _hover={{
                        bgGradient: "linear(to-r, blue.600, blue.400)",
                      }}
                      bgGradient="linear(to-r, blue.500, blue.300)"
                      color="white"
                    >
                      Sign Up
                    </Button>
                  </Link>
                  <Link to="/login" onClick={onClose}>
                    <Button colorScheme="gray">Log In</Button>
                  </Link>
                </>
              )}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Navbar;
