const resources = [
  {
    title: "n8n AI Autoblogging v1",
    description: `
      This is a simple script to get an AI generated blog published to your WordPress in seconds.
      <br><br>
      <iframe width="100%" height="378" src="https://www.youtube.com/embed/V5aTXlhqesM?si=mSBYn3UMGQzVdfNA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <br>
      Get this workflow by upgrading to a premium tier. Or build it yourself for free!
      `,
    tags: ["AI", "Automation", "Business", "n8n", "Free", "Premium"],
    link: "/n8n-autoblog-v1",
    image: "./images/aiblogv1.jpg",
    premium: true,
    externalLink: "https://aiamastery.com/vault-item/n8n-autoblog-v1/download",
    folderId: "1v0VLReBN0UOjqedc8BI1n4fOdKm1Q4cq",
  },
  {
    title: "Apollo Python Web Scraper",
    description:
      "Python webscraper to scrape leads from Apollo.io (HIGHLY TECHNICAL)",
    tags: ["AI", "Automation", "Advanced", "Free"],
    link: "/apollo-python-web-scraper",
    image: "./images/apollojs.jpg",
    premium: false,
    externalLink: "https://mikepowers.gumroad.com/l/apollowebscraper",
  },
  {
    title: "Real Estate Voiceflow Chatbot",
    description:
      "Voiceflow template for your real estate business to prescreen clients.",
    tags: ["AI", "Automation", "Advanced", "Free"],
    link: "/real-estate-voiceflow-chatbot",
    image: "./images/realestate.jpg",
    premium: false,
    externalLink: "https://mikepowers.gumroad.com/l/realestatebot",
  },
  {
    title: "Vapi Phone Prompt",
    description: "Build AI voice agents to automate any phone task with Vapi.",
    tags: ["AI", "Automation", "Business", "Phone", "Free"],
    link: "/vapi-phone-prompt",
    image: "./images/vapibot.jpg",
    premium: false,
    externalLink: "https://mikepowers.gumroad.com/l/vapi-phone-prompt",
  },
  {
    title: "Airbnb Voiceflow Chatbot",
    description:
      "The airbnb chatbot file you can use to import the exact chatbot i made in my video!",
    tags: ["AI", "Automation", "Business", "Chatbot", "Free"],
    link: "/airbnb-chatbot",
    image: "./images/airbnbchat.png",
    premium: false,
    externalLink: "https://mikepowers.gumroad.com/l/airbnb-chatbot",
  },
  {
    title: "Lead Gen Voiceflow Chatbot",
    description: "Building a wealth management lead generation bot!",
    tags: ["AI", "Automation", "Business", "Chatbot", "Free"],
    link: "/lead-gen-bot",
    image: "./images/leadgenbot.jpg",
    premium: false,
    externalLink: "https://mikepowers.gumroad.com/l/lead-gen-bot",
  },
  {
    title: "Google Maps JS Scraper",
    description:
      "This script is designed to scrape all google maps leads with a click of a button.",
    tags: ["AI", "Automation", "Business", "Scraper", "Free"],
    link: "/google-maps-js-scraper",
    image: "./images/gmapsjs.jpg",
    premium: false,
    externalLink: "https://mikepowers.gumroad.com/l/google-maps-scraper",
  },
  {
    title: "Apollo Easy Scrape Chrome Extension",
    description: "The easiest way to scrape apollo for absoutely free.",
    tags: ["AI", "Automation", "Business", "Scraper", "Free"],
    link: "/apollo-easy-scrape",
    image: "./images/apollochromev1.jpg",
    premium: false,
    externalLink: "https://mikepowers.gumroad.com/l/apollo-easy-scrape",
  },
  {
    title: "Custom Linkedin Emails With AI",
    description:
      "A simple script to automate making custom emails from linkedin data.",
    tags: ["AI", "Automation", "Business", "Linkedin", "Free"],
    link: "/custom-linkedin-ai-emails",
    image: "./images/linkedinemails.jpg",
    premium: false,
    externalLink: "https://mikepowers.gumroad.com/l/custom-linkedin-ai-emails",
  },
  {
    title: "Sam.gov contract scraper",
    description:
      "Go to sam.gov and make an account and add your api key. You can run this with python and vs code. Make sure python is installed. ",
    tags: ["AI", "Automation", "Business", "Scraper", "Free"],
    link: "/sam-gov-contract-scraper",
    image: "./images/samgov.jpg",
    premium: false,
    externalLink: "https://mikepowers.gumroad.com/l/sam-gov-contract-scraper",
  },
  {
    title: "Simple HTML Extraction Plugin",
    description:
      "Simple HTML extractor chrome extension to get the html of a current page.",
    tags: ["AI", "Automation", "Business", "Scraper", "Extension", "Free"],
    link: "/simple-html-extraction-plugin",
    image: "./images/simplehtml.jpg",
    premium: false,
    externalLink: "https://mikepowers.gumroad.com/l/sam-gov-contract-scraper",
  },
  {
    title: "Phone Bot Script",
    description:
      "This is the script to run a local phone bot with twilio and GPT.",
    tags: ["AI", "Automation", "Business", "Phone", "Free"],
    link: "/phone-bot-raw-script",
    image: "./images/phonebot.jpg",
    premium: false,
    externalLink: "https://mikepowers.gumroad.com/l/phone-bot",
  },
  {
    title: "Google Maps Easy Scrape",
    description:
      "This Chrome Extension is designed to scrape google maps with just a click of a button.",
    tags: ["AI", "Automation", "Business", "Scraper", "Extension", "Free"],
    link: "/google-maps-easy-scape",
    image: "./images/scrapegooglemaps.jpg",
    premium: false,
    externalLink: "https://mikepowers.gumroad.com/l/google-maps-easy-scape",
  },
  {
    title: "Website Email Scraper",
    description: "This is a script designed to scrape emails from websites.",
    tags: ["AI", "Automation", "Business", "Scraper", "Free"],
    link: "/website-email-scraper",
    image: "./images/websitescraper.jpg",
    premium: false,
    externalLink: "https://mikepowers.gumroad.com/l/website-email-scraper",
  },
  {
    title: "LinkedIn Apify Scraper",
    description: "This is an Apify script to scrape linkedin",
    tags: ["AI", "Automation", "Business", "Scraper", "Linkedin", "Free"],
    link: "/linkedin-apify-scraper",
    image: "./images/apifylinkedin.jpg",
    premium: false,
    externalLink: "https://mikepowers.gumroad.com/l/linkedin-apify-scraper",
  },
  {
    title: "Instagram Automatic Bot",
    description:
      "This is a script designed to automatically repost content on social media forever.",
    tags: ["AI", "Automation", "Business", "Scraper", "Free"],
    link: "/instagram-automatic-bot",
    image: "./images/socialpost.jpg",
    premium: false,
    externalLink: "https://mikepowers.gumroad.com/l/instagram-automatic-bot",
  },
  {
    title: "n8n Tools Workflows",
    description:
      "n8n tools workflows to use other workflows as tools inside of n8n.",
    tags: ["AI", "Automation", "Business", "n8n", "Free"],
    link: "/n8n-tools",
    image: "./images/n8ntools.jpg",
    premium: false,
    externalLink: "https://mikepowers.gumroad.com/l/n8n-tools",
  },
  {
    title: "n8n LinkedIn Scraper",
    description: "Copy and paste this json data into your n8n workflow.",
    tags: ["AI", "Automation", "Business", "n8n", "Free", "Linkedin"],
    link: "/n8n-linkedin-scraper",
    image: "./images/linkedinn8n.jpg",
    premium: false,
    externalLink: "https://mikepowers.gumroad.com/l/n8n-linkedin-scraper",
  },
  {
    title: "One Click TikTok Automation",
    description: "Post from tiktok to youtube and instagram in one click.",
    tags: ["AI", "Automation", "Business", "n8n", "Free"],
    link: "/one-click-tiktok-automation",
    image: "./images/oneclickautomation.jpg",
    premium: false,
    externalLink:
      "https://mikepowers.gumroad.com/l/one-click-tiktok-automation",
  },
  {
    title: "Email Verification Tools",
    description: "Tools for verifying if an email is legit.",
    tags: ["AI", "Automation", "Business", "Email", "Free"],
    link: "/email-verification-script",
    image: "./images/emailverify.jpg",
    premium: false,
    externalLink: "https://mikepowers.gumroad.com/l/email-verification-script",
  },
  {
    title: "Scrape Anything Examples",
    description:
      "These are the scripts I used in my video to scrape any website in seconds.",
    tags: ["AI", "Automation", "Business", "Scraper", "Free"],
    link: "/scrape-anything",
    image: "./images/scrapeanything.jpg",
    premium: false,
    externalLink: "https://mikepowers.gumroad.com/l/scrape-anything",
  },
];

export default resources;
