// src/App.js
import React from 'react';
import { ChakraProvider, Box, extendTheme } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import Signup from './components/Signup';
import Vault from './components/Vault';
import VaultItem from './components/vault/VaultItem';
import Dashboard from './components/Dashboard';
import Settings from './components/Settings';
import Pricing from './components/Pricing';
import Build from './components/Build';
import Request from './components/Request';
import { AuthProvider } from './components/AuthContext';
import Navbar from './components/landing/Navbar';
import Footer from './components/landing/Footer';
import Download from './components/vault/Download';

const theme = extendTheme({
  fonts: {
    body: 'Poppins, sans-serif',
    heading: 'Poppins, sans-serif',
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <Box bgGradient="linear(to-br, #020016, #101b3a)">
          <Router>
          <Navbar/>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/vault" element={<Vault />} />
              <Route path="/build" element={<Build />} />
              <Route path="/request" element={<Request />} />
              <Route path="/vault-item/:resourceId" element={<VaultItem />} />
              <Route path="/vault-item/:resourceId/download" element={<Download />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/settings" element={<Settings />} />
            </Routes>
            <Footer/>
          </Router>
        </Box>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
