import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Grid,
  Button,
  Icon,
} from '@chakra-ui/react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { auth, db, functions } from '../../Firebase';
import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51NPuIWEhPqwUuipqmn4rqD9cZaKo1pckcUajOefm9cRXLO2rRYdhkaYasxJ18AeAHSvQVJsSSTZh0bGbh6GRB2Oj00P0RDozCK');

const PricingPart = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userPlan, setUserPlan] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setIsLoggedIn(!!user);
      if (user) {
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUserPlan(userData.plan);
          } else {
            console.error('User document does not exist');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const handleGetStarted = async (plan) => {
    if (plan === 'Premium' && isLoggedIn) {
      setLoading(true);
      try {
        const createNewPricePlan = httpsCallable(functions, 'createNewPricePlan');
        const result = await createNewPricePlan({
          priceId: 'price_1PQxaXEhPqwUuipqe1NpbawI',
          successUrl: `${window.location.origin}/vault`,
          cancelUrl: `${window.location.origin}/pricing`,
        });
        const { sessionId } = result.data;
        const stripe = await stripePromise;
        stripe.redirectToCheckout({ sessionId });
      } catch (error) {
        console.error('Error creating Stripe checkout session:', error);
        setLoading(false);
      }
    } else {
      navigate(isLoggedIn ? '/vault' : '/signup');
    }
  };

  const plans = [
    {
      name: 'Free',
      price: '$0',
      features: ['Discord Community', 'Skool Community', 'Free Resources'],
      nonFeatures: ['Premium Discord', 'Premium Vault Resources', 'Dedicated Support'],
    },
    {
      name: 'Premium',
      price: '$49/m',
      features: ['Premium Discord', 'Skool Community', 'Free Resources', 'Premium Vault Resources', 'Automation Request'],
      nonFeatures: ['1 on 1 Consultation'],
    },
    {
      name: 'Build With Me',
      price: 'Contact Us',
      features: ['Have an idea? Lets build!', 'White glove support on your app.', 'Dedicated support on your app.', '10 Week Training', 'In Depth AI Support', 'Partnership Opportunities'],
      nonFeatures: [],
    },
  ];

  return (
    <Box py={20} maxW="1200px" mx="auto" px={6}>
      <Flex direction="column" alignItems="center" mb={12}>
        <Heading as="h1" size="2xl" color="white" mb={4}>
          Pricing Plans
        </Heading>
        <Text color="white" fontSize="xl" mb={8} textAlign="center">
          Choose a plan that fits your needs and supercharge your business.
        </Text>
      </Flex>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={8}>
        {plans.map((plan, index) => (
          <Box
            key={index}
            border="1px"
            borderColor="gray.200"
            p={8}
            borderRadius={8}
            bg="rgba(255, 255, 255, 0.1)"
            cursor="pointer"
            transition="all 0.3s ease"
            _hover={{
              transform: 'scale(1.02)',
              boxShadow: '0 0 20px rgba(255, 255, 255, 0.5)',
            }}
            onClick={() => plan.name === 'Build With Me' ? navigate('/build') : handleGetStarted(plan.name)}
          >
            <Flex direction="column" alignItems="center">
              <Heading as="h2" fontSize="4xl" mb={4} color="white">
                {plan.name}
              </Heading>
              <Text fontSize="3xl" fontWeight="bold" mb={4} color="white">
                {plan.price}
              </Text>
            </Flex>
            <Stack spacing={3} mb={8}>
              {plan.features.map((feature, index) => (
                <Flex key={index} align="center">
                  <Icon as={FaCheck} color="green.500" mr={2} />
                  <Text color="white">{feature}</Text>
                </Flex>
              ))}
              {plan.nonFeatures.map((nonFeature, index) => (
                <Flex key={index} align="center">
                  <Icon as={FaTimes} color="red.500" mr={2} />
                  <Text color="white">{nonFeature}</Text>
                </Flex>
              ))}
            </Stack>
            <Button
              w="full"
              bgGradient={userPlan === plan.name ? 'gray.500' : 'linear(to-r, blue.500, blue.300)'}
              color="white"
              isDisabled={loading || userPlan === plan.name || (userPlan === 'Premium' && plan.name === 'Free')}
              _hover={{
                bgGradient: userPlan === plan.name ? 'gray.500' : 'linear(to-r, blue.600, blue.400)',
              }}
            >
              {plan.name === 'Build With Me' ? 'Contact Us' : 
                userPlan === plan.name ? 'Current Plan' :
                plan.name === 'Premium' && userPlan === 'Free' ? loading ? 'Please wait...' : 'Upgrade' :
                plan.name === 'Free' && userPlan === 'Premium' ? 'On Paid Tier' :
                isLoggedIn ? 'Get Started' : 'Start for Free'}
            </Button>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default PricingPart;
