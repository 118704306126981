import React from 'react';
import { Box } from '@chakra-ui/react';
import Navbar from './landing/Navbar';
import Footer from './landing/Footer';

const Dashboard = () => {
  return (
    <Box>
      <Navbar />
      <Footer />
    </Box>
  );
};

export default Dashboard;