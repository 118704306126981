import React from "react";
import {
  Box,
  Heading,
  Button,
  Container,
  Flex,
  Fade,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <Box py={{ base: 10, md: 20 }} mb={300}>
      <Container maxW="container.xl">
        <Flex
          direction="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Fade
            in={true}
            transform="translateY(100px)"
            transition={{ enter: { duration: 1, delay: 0.2 } }}
          >
            <Heading
              as="h1"
              fontSize={{ base: "32px", md: "56px" }}
              color="white"
              mb={4}
            >
              Become A Master
              <br /> At AI And Automation
            </Heading>
            <Text fontSize={{ base: "16px", md: "20px" }} color="gray.300" mb={4}>
              Get started learning with our resources from The Vault.
            </Text>
          </Fade>
          <Fade
            in={true}
            transform="translateY(100px)"
            transition={{ enter: { duration: 1, delay: 0.4 } }}
          >
            <Link to="/vault">
              <Button
                _hover={{
                  bgGradient: "linear(to-r, blue.600, blue.400)",
                }}
                bgGradient="linear(to-r, blue.500, blue.300)"
                color="white"
                fontSize={{ base: "20px", md: "28px" }}
                py={{ base: 5, md: 7 }}
                px={{ base: 8, md: 12 }}
                mb={{ base: 10, md: 20 }}
              >
                The Vault
              </Button>
            </Link>
          </Fade>
          <Fade
            in={true}
            transform="translateY(1000px)"
            transition={{ enter: { duration: 1, delay: 0.6 } }}
          >
            <Box
              as="iframe"
              width={{ base: "100%", md: "600px" }}
              height={{ base: "auto", md: "337.5px" }}
              src="https://www.youtube.com/embed/bK9Yu9KXNUw?si=CDCS-8IfQFOdN_ag"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              borderRadius="25px"
              boxShadow="0 0 0 10px rgba(255, 255, 255, 1)"
              mt={8}
            />
          </Fade>
        </Flex>
      </Container>
    </Box>
  );
};

export default Hero;
