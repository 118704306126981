import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { Box, Button, Center, VStack, Spinner, Text, useToast } from "@chakra-ui/react";
import { auth, db } from "../../Firebase";
import resourcesData from "./Resources";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const API_KEY = 'AIzaSyCqpgaSDfHmsseuMIMy8ojSYRE1WDUi0Ac';

const Download = () => {
  const { resourceId } = useParams();
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(true);
  const toast = useToast();

  const userDocRef = user ? doc(db, "users", user.uid) : null;
  const [userDoc, loading] = useDocument(userDocRef);

  const isPremiumUser = userDoc?.data()?.plan === "Premium";

  const selectedResource = resourcesData.find(
    (resource) => resource.link.split("/")[1] === resourceId
  );

  useEffect(() => {
    if (selectedResource) {
      const fetchFiles = async () => {
        try {
          const response = await fetch(`https://www.googleapis.com/drive/v3/files?q='${selectedResource.folderId}'+in+parents&key=${API_KEY}`);
          const data = await response.json();
          console.log("API Response:", data); // Log the API response
          setFiles(data.files || []);
        } catch (error) {
          console.error('Error fetching files:', error);
        } finally {
          setLoadingFiles(false);
        }
      };

      fetchFiles();
    }
  }, [selectedResource]);

  if (loading || loadingFiles) {
    return (
      <Center height="100vh">
        <Spinner size="xl" color="white" />
      </Center>
    );
  }

  if (!selectedResource) {
    return (
      <Center height="100vh">
        <Text fontSize="md" color="red.500">
          Resource not found or not available for download.
        </Text>
      </Center>
    );
  }

  if (!isPremiumUser) {
    navigate("/pricing");
    return null;
  }

  const handleDownloadAll = async () => {
    const zip = new JSZip();

    const filePromises = files.map(async (file) => {
      const response = await fetch(`https://www.googleapis.com/drive/v3/files/${file.id}?alt=media&key=${API_KEY}`);
      const blob = await response.blob();
      zip.file(file.name, blob);
    });

    await Promise.all(filePromises);

    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, `${resourceId}.zip`);

    toast({
      title: "Download Complete",
      description: "Your files have been downloaded successfully.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const handleBack = () => {
    navigate("/vault-item/n8n-autoblog-v1");
  };

  return (
    <Center height="100vh">
      <Box textAlign="center">
        <Text fontSize="xl" color="white" mb={4}>
          {selectedResource.title}
        </Text>
        {files.length > 0 ? (
          <>
          <VStack>
            <Button
              colorScheme="blue"
              bgGradient="linear(to-r, blue.500, blue.300)"
              _hover={{ bgGradient: "linear(to-r, blue.600, blue.400)" }}
              onClick={handleDownloadAll}
              mb={4}
            >
              Download All as Zip
            </Button>
            <Button
              colorScheme="whiteAlpha"
              variant="outline"
              color="white"
              _hover={{ bg: "whiteAlpha.200" }}
              onClick={handleBack}
            >
              Back
            </Button>
            </VStack>
          </>
        ) : (
          <Text fontSize="md" color="red.500">
            No files found in the folder.
          </Text>
        )}
      </Box>
    </Center>
  );
};

export default Download;
