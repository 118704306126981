import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Box, Heading, FormControl, FormLabel, Input, Button, Text, Image, VStack, HStack, Spinner } from '@chakra-ui/react';
import { useAuth } from './AuthContext';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { updateProfile } from 'firebase/auth';
import icon from '../assets/icon.png';
import googleIcon from '../assets/google.png';

const Signup = () => {
  const { signup, signInWithGoogle } = useAuth();
  const [userExists, setUserExists] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const db = getFirestore();

  const handleSignup = async (e) => {
    e.preventDefault();
    const firstName = e.target.firstName.value;
    const lastName = e.target.lastName.value;
    const email = e.target.email.value;
    const password = e.target.password.value;
    const displayName = `${firstName} ${lastName}`;
    setIsCreatingAccount(true);
    try {
      const userCredential = await signup(email, password);
      await updateProfile(userCredential.user, { displayName });

      // Store the user information in Firestore
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        name: displayName,
        email: userCredential.user.email,
        createdAt: new Date(),
        plan: 'Free',
      });

      navigate('/vault');
    } catch (error) {
      setError(error);
      if (error.code === 'auth/email-already-in-use') {
        setUserExists(true);
      }
    } finally {
      setIsCreatingAccount(false);
    }
  };

  const handleGoogleSignup = async () => {
    setIsCreatingAccount(true);
    try {
      const result = await signInWithGoogle();
      const user = result.user;

      // Store the user information in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        name: user.displayName,
        email: user.email,
        createdAt: new Date(),
        plan: 'Free',
      });

      navigate('/vault');
    } catch (error) {
      setError(error);
    } finally {
      setIsCreatingAccount(false);
    }
  };

  return (
    <Box
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgGradient="linear(to-br, #020016, #1a202c)"
    >
      {isCreatingAccount ? (
        <Spinner color="white" size="xl" />
      ) : (
        <Box
          maxWidth="400px"
          width="100%"
          p={8}
          borderRadius={8}
          boxShadow="lg"
          bg="rgba(0, 0, 0, 0.5)"
          backdropFilter="blur(10px)"
        >
          <VStack spacing={6} align="center">
            <Link to="/">
              <Image src={icon} alt="Logo" w="100px" mb={4} />
            </Link>
            <Heading as="h2" size="xl" textAlign="center" mb={6} color="white">
              Sign Up
            </Heading>
            <form onSubmit={handleSignup} style={{ width: '100%' }}>
              <HStack mb={4}>
                <FormControl id="firstName">
                  <FormLabel color="white">First Name</FormLabel>
                  <Input type="text" name="firstName" bg="rgba(255, 255, 255, 0.1)" color="white" />
                </FormControl>
                <FormControl id="lastName">
                  <FormLabel color="white">Last Name</FormLabel>
                  <Input type="text" name="lastName" bg="rgba(255, 255, 255, 0.1)" color="white" />
                </FormControl>
              </HStack>
              <FormControl id="email" mb={4}>
                <FormLabel color="white">Email address</FormLabel>
                <Input type="email" name="email" bg="rgba(255, 255, 255, 0.1)" color="white" />
              </FormControl>
              <FormControl id="password" mb={6}>
                <FormLabel color="white">Password</FormLabel>
                <Input type="password" name="password" bg="rgba(255, 255, 255, 0.1)" color="white" />
              </FormControl>
              <Button
                type="submit"
                bgGradient="linear(to-r, blue.500, blue.300)"
                color="white"
                size="lg"
                width="full"
                mb={4}
                _hover={{
                  bgGradient: "linear(to-r, blue.600, blue.400)",
                }}
                isLoading={isCreatingAccount}
              >
                Sign Up
              </Button>
            </form>
            <Button
              onClick={handleGoogleSignup}
              bg="gray.300"
              colorScheme="gray"
              size="lg"
              width="100%"
              leftIcon={<Image src={googleIcon} alt="Google" boxSize="20px" />}
              isLoading={isCreatingAccount}
            >
              Sign Up with Google
            </Button>
            {error && <Text color="red.300">Error: {error.message}</Text>}
            {userExists && <Text color="red.300">User already exists. Please log in.</Text>}
            <HStack mt={4}>
              <Text color="white">Already have an account?</Text>
              <Link to="/login">
                <Text color="blue.300" textDecoration="underline">
                  Login
                </Text>
              </Link>
            </HStack>
          </VStack>
        </Box>
      )}
    </Box>
  );
};

export default Signup;
