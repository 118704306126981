import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Box, Heading, FormControl, FormLabel, Input, Button, Text, Image, VStack, HStack } from '@chakra-ui/react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useAuth } from './AuthContext';
import { app } from '../Firebase';
import icon from '../assets/icon.png';
import googleIcon from '../assets/google.png';

const fetchUserData = async (uid) => {
  const db = getFirestore(app);
  const userDocRef = doc(db, 'users', uid);
  const userDocSnapshot = await getDoc(userDocRef);

  if (userDocSnapshot.exists()) {
    return userDocSnapshot.data();
  } else {
    return null;
  }
};

const Login = () => {
  const { login, signInWithGoogle, logout } = useAuth();
  const [userNotFound, setUserNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    setLoading(true);
    try {
      await login(email, password);
      navigate('/vault');
    } catch (error) {
      setError(error);
      if (error.code === 'auth/user-not-found') {
        setUserNotFound(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    setLoading(true);
    try {
      const result = await signInWithGoogle();
      const userData = await fetchUserData(result.user.uid);

      if (userData) {
        navigate('/vault');
      } else {
        setUserNotFound(true);
        await logout();
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgGradient="linear(to-br, #020016, #1a202c)"
    >
      <Box maxWidth="400px" width="100%" p={8} borderRadius={8} boxShadow="lg" bg="rgba(0, 0, 0, 0.5)" backdropFilter="blur(10px)">
        <VStack spacing={6} align="center">
          <Link to="/">
            <Image src={icon} alt="Logo" w="100px" mb={4} />
          </Link>
          <Heading as="h2" size="xl" textAlign="center" mb={6} color="white">
            Login
          </Heading>
          <form onSubmit={handleLogin} style={{ width: '100%' }}>
            <FormControl id="email" mb={4}>
              <FormLabel color="white">Email address</FormLabel>
              <Input type="email" name="email" bg="rgba(255, 255, 255, 0.1)" color="white" />
            </FormControl>
            <FormControl id="password" mb={6}>
              <FormLabel color="white">Password</FormLabel>
              <Input type="password" name="password" bg="rgba(255, 255, 255, 0.1)" color="white" />
            </FormControl>
            <Button
              type="submit"
              bgGradient="linear(to-r, blue.500, blue.300)"
              color="white"
              size="lg"
              width="full"
              mb={4}
              _hover={{
                bgGradient: "linear(to-r, blue.600, blue.400)",
              }}
              isLoading={loading}
            >
              Log In
            </Button>
          </form>
          <Button
            onClick={handleGoogleLogin}
            bg="gray.300"
            colorScheme="gray"
            size="lg"
            width="100%"
            leftIcon={<Image src={googleIcon} alt="Google" boxSize="20px" />}
            isLoading={loading}
          >
            Log In with Google
          </Button>
          {error && <Text color="red.300">Error: {error.message}</Text>}
          {userNotFound && <Text color="red.300">User does not exist. Please sign up.</Text>}
          <HStack mt={4}>
            <Text color="white">Don't have an account?</Text>
            <Link to="/signup">
              <Text color="blue.300" textDecoration="underline">
                Sign Up
              </Text>
            </Link>
          </HStack>
        </VStack>
      </Box>
    </Box>
  );
};

export default Login;
